import { useForm } from 'react-hook-form';
import { useId } from 'react';
import { User } from '../../../user';
import { AccountSettingsPanel } from '../account-settings-panel';
import { Button } from '@common/ui/buttons/button';
import { Form } from '@common/ui/forms/form';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import { useUpdateAccountDetails } from './update-account-details';
import { Trans } from '@common/i18n/trans';
import { useUploadAvatar } from '../avatar/upload-avatar';
import { useRemoveAvatar } from '../avatar/remove-avatar';
import { FormImageSelector } from '@common/ui/images/image-selector';
import { FileUploadProvider } from '@common/uploads/uploader/file-upload-provider';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';

interface Props {
  user: User;
}
export function BasicInfoPanel({ user }: Props) {
  const uploadAvatar = useUploadAvatar({ user });
  const removeAvatar = useRemoveAvatar({ user });
  const formId = useId();
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      avatar: user.avatar,
      channel: user.channel || '',
      description: user.description || '',
      facebook: user.facebook || '',
      twitter: user.twitter || '',
      linkedin: user.linkedin || '',
      instagram: user.instagram || '',
      web: user.web || '',
      id: user.id || undefined,
      company: user.company || '',
    },
  });
  const updateDetails = useUpdateAccountDetails(form);


  return (
    <AccountSettingsPanel
      id={AccountSettingsId.AccountDetails}
      title={<Trans message="Update name and profile image" />}
      actions={
        <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateDetails.isLoading || !form.formState.isValid}
        >
          <Trans message="Save" />
        </Button>
      }
    >
      <Form
        form={form}
        className="flex flex-col flex-col-reverse md:flex-row gap-40 md:gap-80"
        onSubmit={newDetails => {
          updateDetails.mutate(newDetails);
        }}
        id={formId}
      >
        <div className="flex-auto w-full">
          <FormTextField
            className="mb-24"
            name="company"
            maxLength={100}
            label={<Trans message="Company" />}
          />
          {/* <FormTextField
            className="mb-24"
            name="channel"
            maxLength={100}
            label={<Trans message="Channel" />}
          /> */}
          <FormTextField
            inputElementType="textarea"
            rows={2}
            maxLength={500}
            className="mb-24"
            name="description"
            label={<Trans message="Description" />}
          />
          <FormTextField
            className="mb-24"
            name="first_name"
            label={<Trans message="First name" />}
          />
          <FormTextField
            name="last_name"
            className="mb-24"
            label={<Trans message="Last name" />}
          />
          <FormTextField
            name="facebook"
            className="mb-24"
            label={<Trans message="Facebook" />}
          />
          <FormTextField
            name="twitter"
            className="mb-24"
            label={<Trans message="Twitter" />}
          />
          <FormTextField
            name="linkedin"
            className="mb-24"
            label={<Trans message="LinkedIn" />}
          />
          <FormTextField
            name="instagram"
            className="mb-24"
            label={<Trans message="Instagram" />}
          />
          <FormTextField
            name="web"
            className="mb-24"
            label={<Trans message="Web Site" />}
          />
        </div>
        <FileUploadProvider>
          <FormImageSelector
            className="md:mr-80 mt-20"
            variant="avatar"
            previewSize="w-90 h-90"
            showRemoveButton
            name="avatar"
            diskPrefix="avatars"
            label={<Trans message="Profile image" />}
            onChange={url => {
              if (url) {
                uploadAvatar.mutate({ url });
              } else {
                removeAvatar.mutate();
              }
            }}
          />
        </FileUploadProvider>
      </Form>
    </AccountSettingsPanel>
  );
}
