import { Radio } from '../../ui/forms/radio-group/radio';
import { UpsellBillingCycle } from './find-best-price';
import { Trans } from '../../i18n/trans';
import {
  RadioGroup,
  RadioGroupProps,
} from '../../ui/forms/radio-group/radio-group';
import { UpsellLabel } from './upsell-label';
import { Product } from '../product';
import { getCdnMime } from '@common/admin/settings/requests/get-cdn-mime';
import { useEffect, useState } from 'react';

interface BillingCycleRadioProps extends Omit<RadioGroupProps, 'children'> {
  selectedCycle: UpsellBillingCycle;
  onChange: (value: UpsellBillingCycle) => void;
  products?: Product[];
}
export function BillingCycleRadio({
  selectedCycle,
  onChange,
  products,
  ...radioGroupProps
}: BillingCycleRadioProps) {

  const [annualEnable, setAnnualEnable] = useState("false");
  
  // const fetchCdn = getCdnMime();

  // useEffect(() => {
    // mime != "default" ? setMediaInvalid(!ref.current?.canPlayType(mime)) : setMediaInvalid(!ref.current?.canPlayType(entry.mime));
    // const getCdn = async () => {
    //   const result = await fetchCdn;
    //   setAnnualEnable(result.annual.value);
    // }
    // getCdn();
  // }, []);
  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    if (!apiCalled) {
      const fetchCdn = getCdnMime();
      const getCdn = async () => {
        const result = await fetchCdn;
        setAnnualEnable(result.annual.value);
      }
      getCdn();
      setApiCalled(true);
    }
  }, [apiCalled]);
  
  return (
    <RadioGroup {...radioGroupProps}>
      {annualEnable && <Radio
        value="yearly"
        checked={selectedCycle === 'yearly'}
        onChange={e => {
          onChange(e.target.value as UpsellBillingCycle);
        }}
      >
        <Trans message="Annual" />
        <UpsellLabel products={products} />
      </Radio>}

      {annualEnable && <Radio
        value="monthly"
        checked={selectedCycle === 'monthly'}
        onChange={e => {
          onChange(e.target.value as UpsellBillingCycle);
        }}
      >
        <Trans message="Monthly" />
      </Radio>}
    </RadioGroup>
  );
}
